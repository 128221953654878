import Link from 'next/link'
import type { ReactElement } from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

import { Spacer } from '@/components/elements/Spacer'
import { TitleLayout } from '@/components/layout'
import { Head } from '@/components/layouts/Head'

export const NotFoundPage = () => {
  return (
    <Content>
      <Spacer h={{ pc: 70, sp: 36 }} />
      <SectionTitle>
        <SectionTitleText>404</SectionTitleText>
        <SectionSubTitleText>エラー</SectionSubTitleText>
      </SectionTitle>
      <Spacer h={{ pc: 63, sp: 50 }} />
      <div>
        <Title>ページが見つかりませんでした</Title>
        <Spacer h={{ pc: 44, sp: 30 }} />
        <div>
          <Message>お探しのページは見つかりませんでした。</Message>
          <Message>
            ご指定いただいたアドレスが間違っているか、ページが移動または削除された可能性があります。
          </Message>
          <Message>
            大変お手数ですがトップページから再度アクセスしてください。
          </Message>
        </div>
        <Spacer h={{ pc: 44, sp: 26 }} />
        <nav>
          <Link href="/">
            <Anchor>トップへ戻る</Anchor>
          </Link>
        </nav>
      </div>
      <Spacer h={{ pc: 127, sp: 80 }} />
    </Content>
  )
}

NotFoundPage.getLayout = function getLayout(page: ReactElement) {
  const pageTitle = {
    title: '404',
    subTitle: 'エラー',
  }

  const breadCrumbs = [
    { name: 'ホーム', path: '/' },
    { name: '404', path: '' },
  ]

  return (
    <>
      <Head title="404 Not Found" description="" noindex />
      <TitleLayout {...pageTitle} breadCrumbs={breadCrumbs}>
        {page}
      </TitleLayout>
    </>
  )
}

const Content = styled.div`
  width: 1040px;
  margin: 0 auto;

  @media screen and (max-width: 1047px) {
    width: 100%;
    padding: 0 20px;
  }
`

const SectionTitle = styled.div`
  text-align: center;
`

const SectionTitleText = styled.div`
  font-family: ${theme.fontEn};
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 6px;
  color: ${theme.colors.trialBlue};

  @media screen and (max-width: 1047px) {
    font-family: inherit;
    font-weight: bold;
    color: ${theme.colors.textTitle};
    font-size: 26px;
    line-height: 37px;
    margin-bottom: 0;
  }
`

const SectionSubTitleText = styled.p`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  color: ${theme.colors.textTitle};

  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const Title = styled.h2`
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 1.68px;
  line-height: 30px;
  color: ${theme.colors.trialBlue};

  @media screen and (max-width: 1047px) {
    font-size: 20px;
    letter-spacing: 2px;
  }
`

const Message = styled.p`
  font-size: 18px;
  letter-spacing: 0.36px;
  line-height: 32px;
  color: ${theme.colors.textTitle};

  @media screen and (max-width: 1047px) {
    font-size: 15px;
    letter-spacing: 0.6px;
    line-height: 25px;
  }
`

const Anchor = styled.a`
  width: 198px;
  height: 50px;
  background: ${theme.colors.trialBlue};
  color: #ffffff;
  border-radius: 29px;
  display: grid;
  place-content: center;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;

  &:hover {
    background: ${theme.colors.hoverLightBlue};
    transition: 0.3s;
  }
`
